<template>
  <Row class="page5">
    <Col style="width:48%;padding:0 1%;">
      <div class="description">
        <span class='title'><span class="title-text">{{picsTitle}}</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <div class="describeList">
          <span v-if="classifyExplain">{{classifyExplain}}</span>
          <span v-else style="text-align:center;font-size: 18px;">暂无简介......</span>
        </div>
      </div>
      <div class="left">
        <china-map ref="chinaMap" :resdata="resdata"></china-map>
      </div>
    </Col>
    <Col style="width:51.5%;height: 95%;display: flex;flex-direction: column">
      <div class="center-bottom">
        <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
<!--        <videoPlay></videoPlay>-->
<!--        <div class="chart-68" v-if="chooseIndex==0">-->
<!--          <area-chart ref="chart1" id="left_1" :config="cnfigData2"></area-chart>-->
<!--        </div>-->
        <div class="chart-68">
          <area-chart ref="chart1" id="left_1" :config="cnfigData1" :choiceIndex="6"></area-chart>
        </div>
        <div class="chart-32">
          <radar-chart ref="chart2" id="left_2" :data="chatRadarData"></radar-chart>
        </div>
      </div>
      <div class="bottom-list">
        <div class="bottom-left">
          <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <workPics ref="workPic" :isBig="true"></workPics>
        </div>
        <div class="bottom-right" style="margin-left: 2%">
          <span class='title'><span class="title-text">{{photoTitle}}</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <honorPics ref="honor" :isLarge="true"></honorPics>
        </div>
      </div>
    </Col>
    <div class="tabNav">
      <div v-for="(item,index) in classify" :key="index" @click="chooseNav(item,index)" :class="index==chooseIndex?'on_workname':'workname'">{{item.classifyName}}</div>
    </div>
  </Row>
</template>

<script>
import {getDataApi, totalDataApi,getHonorData} from "@/api/dataList";
// import {handleOldNewArrFn} from "@/lib/oldNewArr";

const chinaMap  = () => import('./components/chinaMap');
const workPics = () => import('./components/page2/workPictures');
// const videoPlay =()=> import('./components/workVideo/video')
const areaChart = ()=> import('./components/areaChart');
const radarChart = () => import('./components/radar');
const honorPics = ()=> import('./components/page2/HonoraryPics.vue');

export default {
  name: 'workVideo',
  components: {
    chinaMap,
    workPics,//项目图片
    honorPics, //荣誉图片
    radarChart,
    areaChart,
    // videoPlay
  },
  data() {
    return {
      //tabNav子菜单
      classify:[],
      chooseIndex:0,
      photoTitle:'湖湘公益汇',
      picsTitle:'', //右下角图片标题
      //地图展示项目数据
      resdata:[],
      //项目图片数组
      workPicsList: [],
      cnfigData1: {
        color: '#5CB1C1',
        name: ['（人）', '（个）'],
        data: [
                  {
                    name: '志愿者人数',
                    color: ['#9e70ff', '#6e5eff'],
                    data: [],
                  },
                  {
                    name: '社会组织参与数',
                    color: ['#48cefd', '#5356f1'],
                    data: [],
                  }
        ]
      },
      chatRadarData: {
        title: '各城市数据对比',
        position: ['center', '85%'],
        center: ['50%', '50%'],
        indicator: [
          {text: '长沙市'},
          {text: '株洲市'},
          {text: '湘潭市'},
          {text: '衡阳市'},
          {text: '常德市'},
          {text: '益阳市'},
          {text: '娄底市'},
          {text: '郴州市'},
          {text: '永州市'},
          {text: '怀化市'},
          {text: '湘西'},
          {text: '张家界'},
          {text: '岳阳市'},
          {text: '邵阳市'},
        ],
        data: [
          {
            name: '服务人次',
            color: '#0DF5F8',
            value: [],
          },
          {
            name: '投入款物（万元）',
            color: '#7921AD',
            value: [],
          }
        ]
      },
      resizeFn:null,
      classifyExplain:'',
      vedioShow:false
    }
  },
  created() {
    // this.getData();
  },
  methods: {
    // 获取数据
    async getData() {
      const res = await totalDataApi(30);
      this.classify = res.data.classify;
      this.chooseNav(this.classify[0],0);
    },
    //  点击子菜单选择
    async chooseNav(item,index){
      const res = await getDataApi(item.classifyId);
      this.resdata = res.rows;
      this.chooseIndex = index;
      this.classifyExplain = item.classifyExplain //简介
      this.picsTitle = item.classifyName; //标题随着nav导航选项而变化
      //动态获取图片数据
      this.$refs.workPic.workPicsList2 = item.img;
      this.$refs.workPic.isChoose = !this.$refs.workPic.isChoose; //点击菜单，右下角图片重新渲染滑动
      this.$refs.workPic.loading=false; //加载中
      // 荣誉图片或者荣誉视频部分
      const HonRes = await getHonorData(item.classifyId)
      if(HonRes.code == 200) {
        console.log(HonRes);
        if(HonRes.data.switchOption==1){
          //荣誉图片
          this.$refs.honor.shows = 1
          this.photoTitle = '荣誉图片'
           this.$refs.honor.videoUrl = ''
           this.$refs.honor.perfectPicsListL = []
           this.$refs.honor.honoraryPicsList = item.honorImage;
        }else if(HonRes.data.switchOption==2){
          //精彩视频
          this.$refs.honor.shows = 2
          if(HonRes.data.videoUrl){
            HonRes.data.videoUrl = JSON.parse(HonRes.data.videoUrl)
          }
          this.photoTitle = HonRes.data.videoUrl[0].title||'精彩视频'
          console.log( this.photoTitle,'title');
          this.$refs.honor.honoraryPicsList = []
          this.$refs.honor.perfectPicsListL = []
          this.$refs.honor.videoUrl = HonRes.data.videoUrl[0].url;
         
        }else if(HonRes.data.switchOption==3) {
          //精彩图片
          this.$refs.honor.shows = 3
          if(HonRes.data.photoUrl){
            HonRes.data.photoUrl = JSON.parse(HonRes.data.photoUrl)
          }
          this.photoTitle = '湖湘公益汇'
          this.$refs.honor.videoUrl = '';
          this.$refs.honor.honoraryPicsList = []
          this.$refs.honor.perfectPicsListL =  HonRes.data.photoUrl
        }
      }else {
        this.$refs.honor.honoraryPicsList = [];
        this.$refs.honor.videoUrl = ''
           this.$refs.honor.perfectPicsListL = []
      }
      this.$refs.honor.isChoice = !this.$refs.honor.isChoice; //点击菜单，右下角荣誉图片重新渲染滑动
      this.$refs.honor.load=false; //加载中
      //选择导航栏 把index对应传给value 从而获取不同数据
      for (var i=0;i<this.resdata.length;i++){
        //动态计算每个城市的每个项目总和  (目的：若项目数据都为0，湖南地图显示不同颜色)
        let totalValue = Number(this.resdata[i].list[0].attent) + Number(this.resdata[i].list[0].money)
            + Number(this.resdata[i].list[0].schoolCount) + Number(this.resdata[i].list[0].serviceCount)
            + Number(this.resdata[i].list[0].soprtcount) + Number(this.resdata[i].list[0].volunteerCount)
        this.resdata[i].value = totalValue;
      }

      this.$refs.chinaMap.seriesData = this.resdata
      this.$refs.chinaMap.setMap(); //刷新地图数据
      //每次点击导航选项将之前数据置空 再进行下面操作
      this.$refs.chart1.config.data[0].data = [];
      this.$refs.chart1.config.data[1].data = [];
      this.$refs.chart2.data.data[0].value=[];
      this.$refs.chart2.data.data[1].value=[];

      //各城市的数据遍历获取
      this.resdata.forEach(it=>{
        this.$refs.chart2.data.data[1].value.push(it.list[0].money) //右边投入款物
        this.$refs.chart2.data.data[0].value.push(it.list[0].serviceCount) //右边服务人次
      })
      // 儿童平安小课堂的志愿者人数和社会组织参与数改成覆盖学校数量
      if(item.classifyName=='儿童平安小课堂'){
        this.resdata.forEach(it=>{
          this.$refs.chart1.config.data[0].name = '服务人次';
          this.$refs.chart1.config.data[0].data.push(it.list[0].serviceCount) //右边服务人次
          this.$refs.chart1.config.data[1].name = '覆盖学校数量';
          this.$refs.chart1.config.data[1].data.push(it.list[0].schoolCount) //覆盖学校数量
        })
      }else{
        this.$refs.chart1.choiceIndex = 0;
        // this.choiceIndex = 0
        this.resdata.forEach(it=>{
          this.$refs.chart1.config.data[1].name = '社会组织参与数';
          this.$refs.chart1.config.data[0].name = '志愿者人数'
          this.$refs.chart1.config.data[1].data.push(it.list[0].attent) //社会组织参与数
          this.$refs.chart1.config.data[0].data.push(it.list[0].volunteerCount) //志愿者人数
        })
      }
      //刷新子组件数据
      this.$refs.chart1.setChart();
      this.$refs.chart2.setChart();
    },
  },
  mounted() {
    this.getData();
    this.resizeFn = this.$debounce(()=> {
      this.$refs.chinaMap.setMap();
      this.$refs.chart1.setChart();
      this.$refs.chart2.setChart();
    }, 500)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  }
}
</script>

<style lang="less" scoped>
.page5 {
  height: 98%;
  width: 100%;
  padding: 8px 20px 18px;
  background: #03044A;
  overflow: hidden;
  position: relative;
  .tabNav{
    cursor: pointer;
    width: auto;
    margin: 0 auto;
    height: 4.5%;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 1%;
    position: absolute;
    bottom: -2.5%;
    left: 45%;
    .workname{
      color:#fff;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 38px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      background-color: #4b92e1;
      border-right:1px solid #fff;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }
    .on_workname{
      color:#4b92e1;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 35px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      border-radius: 3px;
      background-color: #fff;
      font-family: "宋体", "Times New Roman", Times, serif;
    }
  }
  .ivu-col {
    height: 100%;
    float: left;
  }

  .left, .center-bottom,.description,.bottom-left,.bottom-right{
    height: 95%;
    border: 1px solid #0D2451;
    position: relative;
    background: #151456;

    .left1 {
      border-bottom: 1px solid #0D2451;
      background: #151456;
    }

    .title {
      position: absolute;
      display: inline-block;
      color: #6EDDF1;
      border: 2px solid #6EDDF1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102E;
        display: inline-block;
        padding: 0 4px;
        white-space: nowrap;
        transform: translateY(-5px);
        font-family: "宋体", "Times New Roman", Times, serif;
      }
    }
  }
  .bottom-left,.bottom-right{
    width: 50%;
    height: 100%!important;
  }
  .left{
    height: 76%!important;
    background: none!important;
    border: none!important;
  }
  .description{
    background: none!important;
    height: 22%!important;
    box-sizing: border-box;
    padding-bottom: 2%;
    margin-bottom: -1.5%;
    //width: 105%!important;;
  }
  .describeList{
    text-indent: 2%;
    display: flex;
    height: 96%;
    width: 98%;
    margin: 2% auto;
    align-items: center;
    color:rgb(167,198,235);
    line-height: 27px;
    font-size: 15px;
    overflow-y: scroll;
    font-family: "华文细黑", Courier New, Courier, monospace;
    span{
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
  .describeList::-webkit-scrollbar {/*滚动条整体样式*/
    width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  .describeList::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: #a1b3dc;
  }
  .describeList::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: rgb(8,25,79);
  }
  .bottom-list{
    width: 100%;
    height: 50%!important;
    display: flex;
    box-sizing: border-box;
  }

  .center-bottom {
    height: 48%;
    margin-bottom: 2%;

    .bottom-radars {
      margin-top: 5px;
      height: 55%;
    }

    .bottom-bars {
      height: 45%;
    }
    .chart-68 {
      width: 68%;
      height: 100%;
      float: left;
    }

    .chart-32 {
      width: 32%;
      height: 100%;
      float: left;
    }
  }

}
</style>
